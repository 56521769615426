@import 'scss/variables';
@import 'scss/mixins';

.insurance-form {
  .form-line {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 16px;
    margin: 24px 0;

    & > * {
      flex-grow: 1;
    }
  }

  // Target Select drop-down
  .pwa-dropdown {
    padding: 20px 16px 20px 24px;
  }

  .family-or-retired-container {
    @media screen and (max-width: map-get($breakpoints, 'medium')) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  .iknr-search-container {
    display: flex;
    flex-direction: column;

    input {
      padding: 20px;
    }
  }
}

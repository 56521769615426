@import 'scss/variables';
@import 'scss/mixins';

.search-container {
  position: relative;

  input {
    @include body;
    padding: 20px 16px;
    width: 100%;
    border: 1px solid $grey-700;
    border-radius: 8px;

    &.with-icon {
      background: #fff url('../../assets/images/icon_search.svg') 18px 50% no-repeat;
      padding-left: 54px;
    }

    &.error {
      border: 1px solid $semantic-red;
    }
  }

  .errorMessage {
    display: block;
    padding-top: 8px;
    font-size: 14px;
    line-height: 14px;
    color: #c13c3c;
    white-space: pre-line;
  }

  input:focus {
    border: 2px solid $avi-green;
    margin: -1px;
  }

  .search-list-container {
    position: absolute;
    width: 100%;
    z-index: 2;
    background: $white;
    box-shadow:
      0 1px 2px rgba(0, 0, 0, 0.1),
      0 8px 16px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    height: 0;
    margin-top: 1px;

    &.show {
      max-height: 350px;
      overflow-y: auto;
      height: auto;
    }
  }
}

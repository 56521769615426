@import 'scss/variables';
@import 'scss/mixins';

.spinner-container {
  display: flex;
  z-index: 10;
  justify-content: center;
  align-items: center;
  height: 120px;
  opacity: 1;
  filter: saturate(0);
  transition: opacity 0.15s;
}
